import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const Success = (props)=>(    
       <>
        <div className="auth_body_wrapper" style={{ backgroundImage: `url(${require('../../assets/img/login_bg.png')})` }}>
            <div className="container xl_container text-center">
                <div className="row h-100 align-items-center">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                        <React.Fragment>
                            <div className="login_logo mb-5">
                                <img src={require('../../assets/img/logo.png')} />
                            </div>

                            <p className="mb-5">
                            Success! You will receive detailed instructions on resetting your password to your  <h5>{props.location.state && props.location.state.state}</h5> email address is the shorted time possible.
                               
                          </p>

                            <div className="form-group">
                                <Link className="btn btn-primary" to="/login">Back to sign in</Link>
                            </div>

                        </React.Fragment>
                    </div>


                </div>
            </div>
        </div>
       </>
    )


export default Success;