import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { ProjectMenuIcon, PortfolioMenuIcon, AngleIcon, CamraIcon, EditIcon, PasswordIcon2, IIcon, HelpIcon, LogoutIcon , Privacy ,Faq } from '../Svg';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';

// connect to store
import { connect } from "react-redux";
// bind actions
import { bindActionCreators } from "redux";

//actions
import { logOut } from "../../redux/actions/Auth";
import {getUserProfile} from "../../redux/actions/User";

// history
import { history } from "../../App";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowLogOutModal: false,
        }
    }

    componentDidMount(){
        this.props.action.getUserProfile()
    }

    onClickYes = () => {
        this.props.action.logOut()
        history.push("/")

    }

    // this function handle on click logout btn & log out the user
    handleOnLogOutClick = () => {
        this.setState({ ShowLogOutModal: !this.state.ShowLogOutModal })


    }
    render() {
        return (
            <div className="min_height">
                <header>
                    <div className="logo">
                        <Link to="/">
                            <img src={require('../../assets/img/logo.png')} />
                        </Link>
                    </div>

                    <div className="primary_menu">
                        {/* <ul>
                            <li><NavLink to="/projects"><span className="menu_icon"><ProjectMenuIcon /></span> Projects</NavLink></li>
                            <li><NavLink to="/portfolio"><span className="menu_icon"><PortfolioMenuIcon /></span> Portfolio</NavLink></li>
                        </ul>  */}
                    </div>

                    <div className="header_dropdown">
                        <Dropdown className="header_dropdown_btn">
                            <Dropdown.Toggle className="no_btn" id="dropdown-basic">
                                <img src={this.props.profilePic} />
                                <span className ="textUpperCase">{this.props.userName} <span className="down_angle"><AngleIcon /></span></span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <div className="header_profile">
                                    <span className="header_pro_img">
                                    <img src={this.props.profilePic} />
                                        {/* <span className="upload_pro_img"> */}
                                            {/* <input type="file" />
                                            <CamraIcon /> */}
                                        {/* </span> */}
                                    </span>
                                    <h4 className ="textUpperCase" >{this.props.userName}</h4>
                                    <p><Link to="/profile">View Profile</Link></p>
                                </div>

                                <ul className="profile_menu">
                                    <li><Link to="/profile"><EditIcon /> Edit My Details</Link></li>
                                    <li><Link to="/changepassword"><PasswordIcon2 /> Change Password</Link></li>
                                    <li><Link to="/terms-and-conditions"><IIcon /> Terms & Conditions</Link></li>
                                    <li><Link to="/privacy-policy"><Privacy /> Privacy & Policy</Link></li>
                                    <li><Link to="/faq"><Faq /> Faq</Link></li>
                                    <li onClick={this.handleOnLogOutClick}><span><LogoutIcon /> Logout</span></li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </header>
                <Modal show={this.state.ShowLogOutModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Logout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="logout-body">Are you sure you want to logout </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default  btn-sm mR-20" variant="secondary" onClick={this.handleOnLogOutClick}>No</button>
                        <button className="btn btn-primary btn-sm btn-blue" variant="primary" onClick={this.onClickYes}>Yes</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        profilePic:state.profile.profilePic,
        userName:state.profile.userName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ logOut, getUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

