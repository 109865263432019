import React from 'react';
import { Helmet } from "react-helmet";


const PrivacyPolicy = () => (
    <div className="body_container">
        <div className="cm_breadcumd">
            <p>Privacy-Policy </p>
        </div>
        <div className="projects_card_wrapper pad56">
             {/* <div >
                        <h5>What is Lorem Ipsum?</h5>
                        <p>Lorem Ip
                        asum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div> */}

<p>Welcome to our Privacy Policy
-- Your privacy is critically important to us.
Drawbridge Solutions is located at:</p>
<p>Drawbridge Solutions
,
8474414142
It is Drawbridge Solutions's policy to respect your privacy regarding any information we may
collect while operating our website. This Privacy Policy applies to drawbridgesolution.com
(hereinafter, "us", "we", or "drawbridgesolution.com"). We respect your privacy and are
committed to protecting personally identifiable information you may provide us through the
Website. We have adopted this privacy policy ("Privacy Policy") to explain what information
may be collected on our Website, how we use this information, and under what circumstances
we may disclose the information to third parties. This Privacy Policy applies only to information
we collect through the Website and does not apply to our collection of information from other
sources.
This Privacy Policy, together with the Terms and conditions posted on our Website, set forth
the general rules and policies governing your use of our Website. Depending on your activities
when visiting our Website, you may be required to agree to additional terms and conditions.
</p>
<h5>- Website Visitors</h5>
<p>Like most website operators, Drawbridge Solutions collects non-personally-identifying
information of the sort that web browsers and servers typically make available, such as the
browser type, language preference, referring site, and the date and time of each visitor request.
Drawbridge Solutions's purpose in collecting non-personally identifying information is to better
understand how Drawbridge Solutions's visitors use its website. From time to time, Drawbridge
Solutions may release non-personally-identifying information in the aggregate, e.g., by
publishing a report on trends in the usage of its website.
Drawbridge Solutions also collects potentially personally-identifying information like Internet
Protocol (IP) addresses for logged in users and for users leaving comments on http://
drawbridgesolution.com blog posts. Drawbridge Solutions only discloses logged in user and
commenter IP addresses under the same circumstances that it uses and discloses personallyidentifying
information as described below.</p>
<h5>- Gathering of Personally-Identifying Information</h5>
<p>Certain visitors to Drawbridge Solutions's websites choose to interact with Drawbridge
Solutions in ways that require Drawbridge Solutions to gather personally-identifying
information. The amount and type of information that Drawbridge Solutions gathers depends
on the nature of the interaction. For example, we ask visitors who sign up for a blog at <a href="http://drawbridgesolution.com" target="_blank">http://drawbridgesolution.com</a> to provide a username and email address.</p>
<h5>- Security</h5>
<p>The security of your Personal Information is important to us, but remember that no method of
transmission over the Internet, or method of electronic storage is 100% secure. While we strive
to use commercially acceptable means to protect your Personal Information, we cannot
guarantee its absolute security.</p>
<h5>- Advertisements</h5>
<p>Ads appearing on our website may be delivered to users by advertising partners, who may set
cookies. These cookies allow the ad server to recognize your computer each time they send
you an online advertisement to compile information about you or others who use your
computer. This information allows ad networks to, among other things, deliver targeted
advertisements that they believe will be of most interest to you. This Privacy Policy covers the
use of cookies by Drawbridge Solutions and does not cover the use of cookies by any
advertisers</p>.
<h5>- Links To External Sites</h5>
<p>Our Service may contain links to external sites that are not operated by us. If you click on a
third party link, you will be directed to that third party's site. We strongly advise you to review
the Privacy Policy and terms and conditions of every site you visit.
We have no control over, and assume no responsibility for the content, privacy policies or
practices of any third party sites, products or services.</p>
<h5>- Aggregated Statistics</h5>
<p>Drawbridge Solutions may collect statistics about the behavior of visitors to its website.
Drawbridge Solutions may display this information publicly or provide it to others. However,
Drawbridge Solutions does not disclose your personally-identifying information.</p>
<h5>- Cookies</h5>
To enrich and perfect your online experience, Drawbridge Solutions uses "Cookies", similar
technologies and services provided by others to display personalized content, appropriate
advertising and store your preferences on your computer.
A cookie is a string of information that a website stores on a visitor's computer, and that the
visitor's browser provides to the website each time the visitor returns. Drawbridge Solutions
uses cookies to help Drawbridge Solutions identify and track visitors, their usage of http://
drawbridgesolution.com, and their website access preferences. Drawbridge Solutions visitors
who do not wish to have cookies placed on their computers should set their browsers to refuse
cookies before using Drawbridge Solutions's websites, with the drawback that certain features
of Drawbridge Solutions's websites may not function properly without the aid of cookies.
By continuing to navigate our website without changing your cookie settings, you hereby
acknowledge and agree to Drawbridge Solutions's use of cookies.
Privacy Policy Changes
Although most changes are likely to be minor, Drawbridge Solutions may change its Privacy
Policy from time to time, and in Drawbridge Solutions's sole discretion. Drawbridge Solutions
encourages visitors to frequently check this page for any changes to its Privacy Policy. Your
continued use of this site after any change in this Privacy Policy will constitute your acceptance
of such change.
- Drawbridge Solutions Privacy Policy
- Credit & Contact Information
This privacy policy was created at <a href="#" target="_blank">https://termsandconditionstemplate.com/privacy-policygenerator/</a>.
If you have any questions about this Privacy Policy, please contact us via or phone.


        </div>
    </div>
)

export default PrivacyPolicy;