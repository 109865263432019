/**
 * @about ResetPassword or this file used for reset the password of user
 * 
 *  */

import React, { Component } from 'react';
import { PasswordIcon, } from '../Svg';
import { isEmail } from '../../utility';
import { Link } from 'react-router-dom';
import InlineError from "../common/InlineError";
// forgot api service
import { _resetPassword, _checkValidToken } from "../../config/api/AuthApi";
// loader
import Loader from "../../components/common/Loader"
// notify
import { notify } from "../../components/common/Toaster";
class ResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {

            password: '',
            passwordError: '',
            confirmPassword: '',
            confirmPasswordError: '',
            isLoading: true,
            showExpireLink: false,
            message: "oops your link is expired"


        };
    };

    componentDidMount() {


        this.checkLinkFirst(this.props.match.params.id)
    }


    // access token
    checkLinkFirst = (token) => {
        try {
            _checkValidToken({ accessToken: token })
                .then(resp => {
                    this.setState({ showExpireLink: false, isLoading: false })

                })
                .catch(err => {
                    this.setState({ showExpireLink: true, isLoading: false })

                })

        } catch (err) {

        }
    }
    // on change input fields
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            emailError: '',
            passwordError: '',
            confirmPasswordError: ''
        })
    }
    // on submit change password user if success
    handleSubmit = e => {
        e.preventDefault();

        if (this.state.password == '')
            this.setState({ passwordError: '*Please enter password.' })
        else if (this.state.confirmPassword == '')
            this.setState({ confirmPasswordError: '*Please enter confirm password' })
        else if (this.state.confirmPassword != this.state.password)
            this.setState({ confirmPasswordError: "*Password and confirm password should be same" })

        else {

            try {
                this.setState({ isLoading: true })
                let req = {
                    password: this.state.password,
                    accessToken: this.props.match.params.id
                }
                _resetPassword(req)
                    .then(resp => {
                        this.setState({ isLoading: false })
                        notify("success", "Password reset successfully")
                        this.props.history.push("/")

                    }).catch(err => {
                        this.setState({ isLoading: false })

                        if (err.error)
                            notify("err", err.error.message)
                        else if (err)
                            notify("err", err.message)


                    })

            } catch (err) {
                this.setState({ isLoading: false })
                notify("err", err.message)

            }
        }
    }

    render() {

        let { email } = this.state;
        return (
            <div className="auth_body_wrapper" style={{ backgroundImage: `url(${require('../../assets/img/login_bg.png')})` }}>
                {this.state.isLoading && <Loader />}
                <div className="container xl_container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            {
                                this.state.isLoading == true ?
                                    null
                                    : this.state.showExpireLink ?
                                        <p> {this.state.message}
                                           This reset password link is no more valid. 
                                           It has already been used to reset your password or it has expired. 

                                </p>
                                        :

                                        <form className="pl-5 pr-5 text-center" onSubmit={this.handleSubmit}>
                                            <div className="login_logo mb-5">
                                                <img src={require('../../assets/img/logo.png')} />
                                            </div>

                                            <h3 className="mb-4">Reset password</h3>

                                            {/* <label className=""><h6>New Password:</h6></label> */}
                                            <div className="form-group">
                                                <span className="input_icon"><PasswordIcon /></span>
                                                <input type="password" name="password" onChange={this.handleChange}
                                                    maxLength={20}
                                                    className={`form-control icon_form ${this.state.passwordError != '' ? 'is-invalid' : ''}`}
                                                    placeholder="New password" />
                                                <InlineError
                                                    message={this.state.passwordError}
                                                />
                                            </div>

                                            {/* <label className=""><h6>Confirm Password:</h6></label> */}
                                            <div className="form-group">
                                                <span className="input_icon"><PasswordIcon /></span>
                                                <input type="password" name="confirmPassword"
                                                    maxLength={20}
                                                    onChange={this.handleChange}
                                                    className={`form-control icon_form ${this.state.confirmPasswordError != '' ? 'is-invalid' : ''}`}
                                                    placeholder="Confirm password " />
                                                <InlineError
                                                    message={this.state.confirmPasswordError}
                                                />
                                            </div>
                                            <br />
                                                                                       
                                            {/* <div class="form-group">
                                                <button type="button" class="btn btn-danger mR-20" onClick={() => this.props.history.push("/")}>Cancel</button>
                                                <button type="submit" className="btn btn-primary">Save</button>
                                          
                                            </div> */}
                                            <div className="form-group d-flex justify-content-between">
                                                <Link className="align-self-center forgot_pass" to="/login">Cancel</Link>
                                                <button type="submit" className="btn btn-primary">Save</button>
                                            </div>

                                        </form>
                            }
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword;