/**
* @about app js file contains routing of app
* routing app
* react-router-dom
*/
// react lib
import React, { Component } from 'react';
// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// create history
import { createBrowserHistory } from 'history';
import Layout from "./components/layout/Layout";
import Login from './components/login/Login';
import Success from './components/login/Success'
import ForgotPassword from './components/login/ForgotPassword';
import ResetPassword from "./components/login/ResetPassword";


//Private route
const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props =>
                loggedIn === true ? (
                    <Component {...rest} {...props} />
                ) : (
                        <Redirect
                            to={{ pathname: "/", state: { from: props.location } }}
                        />
                    )
            }
        />
    )
}


export const history = createBrowserHistory();

history.listen(({ pathname }) => {
    window.scrollTo(0, 0)
})

class App extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch> 
                <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/changepassword" component={Layout} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/reset-password/:id" component={ResetPassword} />

                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/profile"
                        component={Layout}
                    /> {}
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/site"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/map"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/media"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/reports"
                        component={Layout}
                    />

                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/resources"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/task"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/overview"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/analysis"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/site/:sId/map"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/site/:sId/media"
                        component={Layout}
                    />

                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/site/:sId/reports"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/site/:sId/resources"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/site/:sId/task"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/site/:sId/overview"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/projects/:id/site/:sId/analysis"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/terms-and-conditions"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/faq"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/privacy-policy"
                        component={Layout}
                    />
                    <PrivateRoute
                        exact
                        loggedIn={localStorage.getItem("isLoggedIn") == "true"}
                        path="/portfolio"
                        component={Layout}
                    />


                    <Route path="/success" component={Success} />

                </Switch>
            </Router>
        )
    }
}

// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        // tagsList: state.tags.allTagsList
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({}, dispatch)
    }
}
// getAllTags
export default connect(mapStateToProps, mapDispatchToProps)(App);