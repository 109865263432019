import React from 'react';

const Faq = () => (
    <div className="body_container">
        <div className="cm_breadcumd">
            <p>Faq</p>
        </div>
        <div className="projects_card_wrapper pad56">
            
            <h5>THE DRAWBRIDGE
PLATFORM: HOW IT WORKS</h5>
            <p>DrawBridge Solutions, LLC created The DrawBridge platform to
            assist owners of environmentally impacted and/or underutilized
            real estate assets to monetize dormant assets, mitigate long-term
            potential liabilities, enhance corporate balance sheets and grow
            earnings, and help communities assess, identify and prioritize
properties for strategic and catalytic redevelopment.</p>
            <h5>MULTIPLE USES. MULTIPLE BENEFITS.</h5>
            <p>The DrawBridge helps owners identify potential property recovery based on
            specific redevelopment opportunities and quickly target appropriate and qualified
            buyer pools—thus, transitioning into a dynamic and easy to use, commercial real
            estate marketing tool. The DrawBridge expedites the underwriting process
            providing identified buyers with a single, informative due diligence dashboard
            application presenting data, analytics and transaction-tested strategic
            recommendations in both an interactive visual mapping tool and a
comprehensive resource library.</p>
            <h5>COMPREHENSIVE RESEARCH.
EXPERT INTEGRATION.</h5>
            <p><p>DrawBridge will generate a wide range of mission-critical information to facilitate
transactions. This work may include the following:</p>

                <p>• Collecting, aggregating and analyzing information in each of the following
                areas of critical interest to prospective buyers across the spectrum of
relevant asset classes:</p>

                <p>• Real estate market information</p>

                <p>• Environmental conditions (known and potential)</p>

                <p>• Regulatory status/assessment</p>

                <p>• Property ownership and history</p>

                <p>• Relevant incentives (federal, state and local)</p>

                <p>• Stakeholders (governmental and private)</p>

                <p>• Economic and demographic data</p>

                <p>• Infrastructure identification and assessment</p>

                <p>• Risk mitigation options/strategies</p>

                <p>DrawBridge can provide multiple tools to facilitate potential agreements:</p>
                <p>• GIS-based mapping tools and data management</p>
                <p>• Site-specific and area-wide redevelopment and repositioning assessments</p>
                <p>• Generating white papers, analytical reports and other documents/output
                regarding various topics and issues relevant to the financial, legal and
                practical feasibility of acquiring and redeveloping particular sites or
portfolios (“DBS Reports”)</p>
                <p>• Integrated risk-management and value-recovery options/alternatives and
                associated recommendations, as well as experienced implementation/
execution resources and strategies</p>
                For any questions, please reach out to: <a href="info@drawbridgesolution.com" target="_blank">info@drawbridgesolution.com</a></p>

        </div>
    </div>
)

export default Faq;