import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Header from './Header';
import Projects from '../projects/Projects';
import ProjectDetail from '../projects/ProjectDetail';
import Profolio from "../profolio/Profolio"
import TermsConditions from '../other/TermsConditions';
import Faq from '../other/Faq';
import PrivacyPolicy from '../other/PrivacyPolicy';
import ChangePassword from '../login/ChangePassword';
import Profile from '../profile/Profile';
import SiteDetails from "../sites/SitesDetail"

class Layout extends Component {
    render() {
        return (
            <div className="site_wrapper">
                <Header />

                <>

                    <Route exact path="/changepassword" component={ChangePassword} />
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/terms-and-conditions" component={TermsConditions} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />

                    <Route exact path="/projects" component={Projects} />
                    
                    <Route exact path="/projects/:id/site" component={ProjectDetail} />
                    <Route exact path="/projects/:id/map" component={ProjectDetail} />
                    <Route exact path="/projects/:id/media" component={ProjectDetail} />                    
                    <Route exact path="/projects/:id/reports" component={ProjectDetail} />
                    <Route exact path="/projects/:id/resources" component={ProjectDetail} />
                    <Route exact path="/projects/:id/task" component={ProjectDetail} />
                    <Route exact path="/projects/:id/overview" component={ProjectDetail} />
                    <Route exact path="/projects/:id/analysis" component={ProjectDetail} />
                    <Route exact path="/projects/:id/sifter" component={ProjectDetail} />
                    <Route exact path="/projects/:id/site/:sId/map" component={SiteDetails} />
                    <Route exact path="/projects/:id/site/:sId/media" component={SiteDetails} />
                    <Route exact path="/projects/:id/site/:sId/media/:eId" component={SiteDetails} />
                    <Route exact path="/projects/:id/site/:sId/reports" component={SiteDetails} />
                    <Route exact path="/projects/:id/site/:sId/resources" component={SiteDetails} />
                    <Route exact path="/projects/:id/site/:sId/task" component={SiteDetails} />
                    <Route exact path="/projects/:id/site/:sId/sifter" component={SiteDetails} />
                    <Route exact path="/projects/:id/site/:sId/analysis" component={SiteDetails} />
                    <Route exact path="/projects/:id/site/:sId/overview" component={SiteDetails} />

                    <Route exact path="/portfolio" component={ Profolio } />
                   
                 
                </>

                <div className="copyright footer_fix">
                    <p>©2020 DrawBridge. All Rights Reserved.</p>
                </div>
            </div>
        )
    }
}

export default Layout;